<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['options'],
  data () {
    return {
      chartoptions: {
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label (tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label
              const value = tooltipItem.yLabel
              const hours = Math.floor(value / 60 / 60)
              const minutes = Math.floor(value / 60) - (hours * 60)
              const seconds = Math.round(value % 60)
              const formatted = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
              return `${label}: ${formatted}`
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                return value + ' seconds'
              }
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.chartoptions)
  }
}
</script>
