<script>
import { Doughnut } from 'vue-chartjs'
// import '@/plugins/annotation'

export default {
  extends: Doughnut,
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      chartOptions: {
        tooltips: {
          callbacks: {
            label (tooltipItem, data) {
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              const label = data.labels[tooltipItem.index]
              return `${label}: ${value}%`
            }
          }
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.chartOptions)
  }
}
</script>
