<script>
import { Doughnut } from 'vue-chartjs'
// import '@/plugins/annotation'

export default {
  extends: Doughnut,
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      chartOptions: {
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.chartOptions)
  }
}
</script>
