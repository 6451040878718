<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['options'],
  data () {
    return {
      chartoptions: {
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            title (tooltipItem, data) {
              const title = tooltipItem[0].label
              if (parseInt(title) >= 0 && parseInt(title) < 12) {
                return title + ' AM'
              } else if (parseInt(title) >= 12 && parseInt(title) <= 23) {
                return title + ' PM'
              }
            },
            label (tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label
              const value = tooltipItem.yLabel
              return `${label}: ${value} users`
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.chartoptions)
  }
}
</script>
