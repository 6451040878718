<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['options'],
  data () {
    return {
      chartoptions: {
        tooltips: {
          intersect: false,
          callbacks: {
            title (tooltipItem, data) {
              const xlabel = tooltipItem[0].xLabel
              if (xlabel === '1') {
                return `${xlabel} day since last session`
              } else {
                return `${xlabel} days since last session`
              }
            },
            label (tooltipItem, data) {
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              // const label = data.labels[tooltipItem.index]
              return `${value} sessions`
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Days since last Session'
            },
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.chartoptions)
  }
}
</script>
